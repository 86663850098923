@font-face {
  font-display: swap;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  src: url("open-sans-v36-latin_latin-ext-regular.869359f5.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Open Sans;
  font-style: italic;
  font-weight: 400;
  src: url("open-sans-v36-latin_latin-ext-italic.4f133476.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  src: url("open-sans-v36-latin_latin-ext-700.114140df.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Open Sans;
  font-style: italic;
  font-weight: 700;
  src: url("open-sans-v36-latin_latin-ext-700italic.9a980e7e.woff2") format("woff2");
}

/*# sourceMappingURL=index.149cc30e.css.map */
